import React from "react"

import { useStaticQuery, graphql, Link } from "gatsby"

import Img from "gatsby-image"
import { a, useSpring } from "react-spring"

import newArrivalsStyles from "./newArrivals.module.scss"

function NewArrivals({ collection }) {
  const newArrivalQuery = useStaticQuery(graphql`
    query {
      women: shopifyCollection(title: { eq: "New Arrivals Women" }) {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      plus: shopifyCollection(title: { eq: "New Arrivals Plus" }) {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
      maternity: shopifyCollection(title: { eq: "New Arrivals Maternity" }) {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const newArrivalImg =
    collection === "women"
      ? newArrivalQuery.women.image.localFile.childImageSharp.fluid
      : collection === "plus"
      ? newArrivalQuery.plus.image.localFile.childImageSharp.fluid
      : newArrivalQuery.maternity.image.localFile.childImageSharp.fluid

  const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 100,
    (x - window.innerWidth / 2) / 100,
    1.1,
  ]
  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  return (
    <a.div
      className={newArrivalsStyles.container}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.to(trans) }}
    >
      <Link
        className={newArrivalsStyles.imgLink}
        to={`/new-arrivals/${collection}`}
      >
        <div className={newArrivalsStyles.img}>
          <Img alt={`New Arrivals ${collection}`} fluid={newArrivalImg} />
        </div>
      </Link>
    </a.div>
  )
}

export default NewArrivals
