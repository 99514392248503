import React from "react"

import HELM from "../../components/helmet.js"
import Layout from "../../components/layout/layout.js"

import newStyles from "../../utilities/pageStyles/new.module.scss"
import NewArrivals from "../../components/newArrivals/newArrivals"

export default function New() {
  return (
    <Layout>
      <HELM
        title="New Arrivals"
        keywords={[`New Clothes`, `New Arrivals`, `Fashion`, `Trend`]}
      />
      <div className={newStyles.newArrivalCards}>
        <NewArrivals collection="women" />

        <NewArrivals collection="plus" />

        <NewArrivals collection="maternity" />
      </div>
    </Layout>
  )
}
